// Room Rent type api
export const manpowerManagementList = '/ams/manpower-management/list'
export const manpowerManagementStore = '/ams/manpower-management/store'
export const manpowerManagementUpdate = '/ams/manpower-management/update'
export const manpowerManagementToggleStatus = '/ams/manpower-management/toggle-status'
export const manpowerManagementDestroy = '/ams/manpower-management/destroy'

// Accommodation Management api
export const accommodationManagementList = '/ams/accommodation-managements/list'
export const accommodationManagementListBina = '/ams/accommodation-managements/list-bina'
export const accommodationManagementStore = '/ams/accommodation-managements/store'
export const accommodationManagementShow = '/ams/accommodation-managements/show'
export const accommodationManagementUpdate = '/ams/accommodation-managements/update'
export const accommodationManagementToggleStatus = '/ams/accommodation-managements/toggle-status'
export const accommodationManagementDestroy = '/ams/accommodation-managements/destroy'
export const AccommodationServiceRequestApproveReject = '/ams/accommodation-managements/ar-store'
export const AccommodationServiceRequestApproveRejectBina = '/ams/accommodation-managements/ar-store-bina'

// Accomodation Setup api
export const accomodationSetupBaseUrl = '/config/master-accomodation-setup/'
export const accomodationSetupList = accomodationSetupBaseUrl + 'list'
export const accomodationSetupToggleStatus = accomodationSetupBaseUrl + 'toggle-status'
export const accomodationSetupStore = accomodationSetupBaseUrl + 'store'
export const accomodationSetupUpdate = accomodationSetupBaseUrl + 'update'
export const accomodationSetupDestroy = accomodationSetupBaseUrl + 'destroy'

// Bill Generate api
export const billGenerateList = '/ams/bill-generates/list'
export const billGenerateStore = '/ams/bill-generates/store'
export const billGenerateShow = '/ams/bill-generates/show'
export const billGenerateUpdate = '/ams/bill-generates/update'
export const billGenerateToggleStatus = '/ams/bill-generates/toggle-status'
export const billGenerateDestroy = '/ams/bill-generates/destroy'
export const billGeneratePayment = '/ams/bill-generates/payment'
export const billGeneratePaymentStore = '/training-e-learning-service-payment/payment-accommodation'
export const billGeneratePaymentUpdate = '/training-e-learning-service-payment/'
export const billGenerateVatTax = '/ams/bill-generates/vat-tax'

// Room Type api
export const roomTypeList = '/ams/room-types/list'
export const roomTypeStore = '/ams/room-types/store'
export const roomTypeUpdate = '/ams/room-types/update'
export const roomTypeToggleStatus = '/ams/room-types/toggle-status'
export const roomTypeDestroy = '/ams/room-types/destroy'

// Room Rent type api
export const roomRentList = '/ams/room-rents/list'
export const roomRentStore = '/ams/room-rents/store'
export const roomRentUpdate = '/ams/room-rents/update'
export const roomRentToggleStatus = '/ams/room-rents/toggle-status'
export const roomRentDestroy = '/ams/room-rents/destroy'

// Grade Setup api
export const gradeSetupList = '/config/grade-setup/list'
export const gradeSetupStore = '/config/grade-setup/store'
export const gradeSetupUpdate = '/config/grade-setup/update'
export const gradeSetupShow = '/config/grade-setup/show'
export const gradeSetupToggleStatus = '/config/grade-setup/toggle-status'
export const gradeSetupDestroy = '/config/grade-setup/destroy'

// Filter Circular List
export const circularPublicationBaseUrl = '/iabm/circular-publications/'
export const circularList = circularPublicationBaseUrl + 'circular-publication-list'

// Booking api
export const booingBaseUrl = '/iabm/booking/'
export const bookingApplication = booingBaseUrl + 'application'
export const bookingApplicationStore = booingBaseUrl + 'store'
export const bookingApplicationCheck = booingBaseUrl + 'room-book-check'
export const bookingApplicationList = booingBaseUrl + 'list'
export const bookingCancel = booingBaseUrl + 'cancel'
export const cancelComments = booingBaseUrl + 'cancel-comments'
export const roomList = booingBaseUrl + 'room-list'
export const roomBookedList = booingBaseUrl + 'room-book-list'
